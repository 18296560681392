import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  FormFieldType,
  isCheckbox,
  isColumns,
  isSwitch,
  isInput,
  isLegend,
  isRadioGroup,
  isSelect,
  isTextArea,
  isTextEditor,
  ThemeButtonColors
} from 'types'
import {
  handleError,
  is403,
  is404,
  isNullString,
  makeFieldError,
  RequestError
} from 'utils'
import { Alert, ButtonSubmit, ErrMsg } from 'components'
import {
  Checkbox,
  Columns,
  Input,
  Label,
  Legend,
  RadioGroup,
  Select,
  Switch,
  TextArea,
  TextEditor
} from '..'
import { FormFooter, FormView } from './Form.styled'
import { SwitchLabel, SwitchLabelWrapper } from '../Switch/Switch.styled'

const Form = <T extends Record<string, any>>({
  fields,
  data,
  error,
  submit,
  isLoading,
  scrollWindow,
  children,
  extraFields,
  isRelation = false,
  submitText = 'Submit',
  submitColor = 'primary',
  suppress404 = false,
  style,
  className,
  onValuesChange,
  disabled = false
}: {
  fields: FormFieldType[] | ((values: T | null) => FormFieldType[])
  data: T | null
  error?: RequestError
  submit?: (values: any) => void
  required?: string[]
  isLoading: boolean
  scrollWindow?: HTMLDivElement | null
  children?: React.ReactNode
  extraFields?: (values: T, errors: Record<string, unknown>) => React.ReactNode
  isRelation?: boolean
  submitText?: string
  submitColor?: keyof ThemeButtonColors
  suppress404?: boolean
  style?: object
  className?: string
  onValuesChange?: (values: T | null) => void
  disabled?: boolean
}) => {
  const formRef = useRef<HTMLFormElement>(null)
  const [values, setValues] = useState<T>((data || {}) as T)
  const [errors, setErrors] = useState<Record<string, unknown>>({})
  const errMsg = errors ? (errors.form as string) : null

  const parsedFields = useMemo<FormFieldType[]>(
    () => (Array.isArray(fields) ? fields : fields(values)),
    [fields, values]
  )

  useEffect(() => {
    if (!onValuesChange) return
    onValuesChange(values)
  }, [onValuesChange, values])

  useEffect(() => {
    if (!isLoading) {
      const err = is404(error) && suppress404 ? null : error
      if (err) {
        setErrors(handleError(err, isRelation))
      } else {
        setErrors({})
        if (data) setValues(data)
      }
    }
  }, [isLoading, data, error, isRelation, suppress404])

  useEffect(() => {
    if (!isLoading) {
      scrollWindow ? (scrollWindow.scrollTop = 0) : window.scroll(0, 0)
    }
  }, [isLoading, scrollWindow])

  const handleInput =
    (name: string, type: string, nullable?: boolean) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const val =
        type === 'color'
          ? event.target.value.replace('#', '')
          : type === 'number' || type === 'range'
            ? Number(event.target.value)
            : event.target.value
      const value = nullable ? val || null : val
      setValues(prevState => ({ ...prevState, [name]: value }))
    }

  const handleSelect =
    (name: string, nullable?: boolean) =>
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const intVal = Number(event.target.value)
      const val = !intVal && intVal !== 0 ? event.target.value : intVal
      const value =
        nullable && intVal !== 0 && (!val || isNullString(val)) ? null : val
      setValues(prevState => ({ ...prevState, [name]: value }))
    }

  const handleTextArea =
    (name: string, nullable?: boolean) =>
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const val = event.target.value
      const value = nullable ? val || null : val
      setValues(prevState => ({ ...prevState, [name]: value }))
    }

  const handleCheckbox =
    (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues(prevState => ({ ...prevState, [name]: event.target.checked }))
    }

  const handleSwitch = (name: string) => (value: boolean) => {
    setValues(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  const handleRadioGroup =
    (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues(prevState => ({ ...prevState, [name]: event.target.value }))
    }

  const handleColumns = (name: string) => (selected: string[]) => {
    setValues(prevState => ({ ...prevState, [name]: selected }))
  }

  const handleSubmit = () => {
    submit && submit(values)
  }

  const setValue = (
    name: string | number,
    newValue: string | number | null
  ) => {
    setValues(prevState => ({ ...prevState, [name]: newValue }))
  }

  if (is403(errMsg)) {
    return (
      <FormView>
        <Alert alertType="ERROR">
          Your current access permissions do not allow you to view or edit this
          page. Please check in with your Checkmate administrator.
        </Alert>
      </FormView>
    )
  }

  return (
    <FormView
      className={className}
      onSubmit={handleSubmit}
      ref={formRef}
      style={style}
    >
      {errMsg ? (
        <ErrMsg errMsg={errMsg} style={{ margin: '0 0 2.8rem' }} />
      ) : null}
      {parsedFields.map(field => {
        const label = field.label || field.name
        const value = values[field.name]
        const error = makeFieldError(errors[field.name], field.error)

        if (isInput(field)) {
          return (
            <Input
              fieldType="Input"
              key={field.name}
              label={label}
              parenthetical={field.parenthetical}
              tooltip={field.tooltip}
              name={field.name}
              type={field.type}
              value={value ?? ''}
              onChange={handleInput(field.name, field.type, field.nullable)}
              error={error}
              setValue={setValue}
              placeholder={field.placeholder}
              required={field.required}
              nullable={field.nullable}
              disabled={field.disabled || disabled}
              readOnly={field.readOnly}
              autoComplete={field.autoComplete}
              pattern={field.pattern}
              min={field.min}
              max={field.max}
            />
          )
        } else if (isSelect(field)) {
          return (
            <Select
              fieldType="Select"
              key={field.name}
              label={label}
              parenthetical={field.parenthetical}
              tooltip={field.tooltip}
              name={field.name}
              value={value ?? ''}
              onChange={handleSelect(field.name, field.nullable)}
              error={error}
              options={field.options}
              required={field.required}
              disabled={field.disabled || disabled}
            />
          )
        } else if (isTextArea(field)) {
          return (
            <TextArea
              fieldType="TextArea"
              key={field.name}
              label={label}
              parenthetical={field.parenthetical}
              tooltip={field.tooltip}
              name={field.name}
              value={value ?? ''}
              onChange={handleTextArea(field.name, field.nullable)}
              error={error}
              required={field.required}
              disabled={field.disabled || disabled}
            />
          )
        } else if (isTextEditor(field)) {
          return (
            <TextEditor
              fieldType="TextEditor"
              key={field.name}
              label={label}
              parenthetical={field.parenthetical}
              tooltip={field.tooltip}
              name={field.name}
              value={value}
              onChange={handleTextArea(field.name, field.nullable)}
              error={error}
              required={field.required}
              disabled={field.disabled || disabled}
            />
          )
        } else if (isRadioGroup(field)) {
          return (
            <RadioGroup
              key={field.name}
              name={field.name}
              label={label}
              options={field.options}
              value={value}
              onChange={handleRadioGroup(field.name)}
            />
          )
        } else if (isCheckbox(field)) {
          return (
            <Checkbox
              fieldType="Checkbox"
              key={field.name}
              label={label}
              parenthetical={field.parenthetical}
              tooltip={field.tooltip}
              name={field.name}
              checked={value ?? false}
              onChange={handleCheckbox(field.name)}
              disabled={field.disabled || disabled}
              error={error}
            />
          )
        } else if (isColumns(field)) {
          return (
            <Columns
              fieldType="Columns"
              key={field.name}
              name={field.name}
              label={label}
              parenthetical={field.parenthetical}
              tooltip={field.tooltip}
              onChange={handleColumns(field.name)}
              value={value}
              options={field.options}
              error={error}
              required={field.required}
              disabled={field.disabled || disabled}
            />
          )
        } else if (isSwitch(field)) {
          return (
            <SwitchLabelWrapper key={field.name}>
              <SwitchLabel>
                <Label
                  htmlFor={field.name}
                  label={field.label}
                  required={field.required || false}
                  errMsg={field.error}
                />
              </SwitchLabel>
              <Switch
                name={field.name}
                value={value ?? false}
                setValue={handleSwitch(field.name)}
                disabled={field.disabled || disabled}
              />
            </SwitchLabelWrapper>
          )
        } else if (isLegend(field)) {
          return (
            <Legend
              fieldType="Legend"
              key={field.name}
              name={field.name}
              label={label}
              subtitle={field.subtitle}
            />
          )
        } else {
          return null
        }
      })}
      {extraFields && extraFields(values, errors)}
      <FormFooter>
        {submit && (
          <ButtonSubmit
            text={submitText}
            color={submitColor}
            onClick={handleSubmit}
            disabled={isLoading}
          />
        )}
        {children}
      </FormFooter>
    </FormView>
  )
}

export default Form
