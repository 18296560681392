import { Camera, LightBulb, Lock } from 'iconoir-react'
import IphoneBody from '../IPhone'
import {
  IPhoneLockScreenBottomBar,
  IPhoneLockScreenContainer,
  IPhoneLockScreenDate,
  IPhoneLockScreenLockIconContainer,
  IPhoneLockScreenMessage,
  IPhoneLockScreenBrandLogo,
  IPhoneLockScreenNotification,
  IPhoneLockScreenQuickActionIcon,
  IPhoneLockScreenQuickActionsContainer,
  IPhoneLockScreenTime,
  IPhoneLockScreenContent,
  IPhoneLockScreenContentTime,
  IPhoneLockScreenTitle,
  IPhoneLockScreenSubtitle
} from './IPhoneLockScreen.styled'
import NotificationIcon from 'components/NotificationIcon'
import { CampaignContent } from 'types'
import { format } from 'date-fns-tz'

interface IPhoneLockScreenProps {
  content: CampaignContent | null
}

const IPhoneLockScreen = ({ content }: IPhoneLockScreenProps) => {
  const { subject, preview_text, body } = content || {}

  const date = new Date()
  return (
    <IphoneBody>
      <IPhoneLockScreenContainer>
        <IPhoneLockScreenLockIconContainer>
          <Lock />
        </IPhoneLockScreenLockIconContainer>
        <IPhoneLockScreenTime>{format(date, 'hh:mm')}</IPhoneLockScreenTime>
        <IPhoneLockScreenDate>
          {format(date, 'EEEE, do MMMM')}
        </IPhoneLockScreenDate>
        <IPhoneLockScreenNotification>
          <IPhoneLockScreenBrandLogo>
            <NotificationIcon />
          </IPhoneLockScreenBrandLogo>
          <IPhoneLockScreenContent>
            <IPhoneLockScreenTitle>{subject}</IPhoneLockScreenTitle>
            <IPhoneLockScreenSubtitle>{preview_text}</IPhoneLockScreenSubtitle>
            <IPhoneLockScreenMessage
              dangerouslySetInnerHTML={{ __html: body || '' }}
            />
          </IPhoneLockScreenContent>
          <IPhoneLockScreenContentTime>18m ago</IPhoneLockScreenContentTime>
        </IPhoneLockScreenNotification>
        <IPhoneLockScreenQuickActionsContainer>
          <IPhoneLockScreenQuickActionIcon>
            <LightBulb />
          </IPhoneLockScreenQuickActionIcon>
          <IPhoneLockScreenQuickActionIcon>
            <Camera />
          </IPhoneLockScreenQuickActionIcon>
        </IPhoneLockScreenQuickActionsContainer>
        <IPhoneLockScreenBottomBar />
      </IPhoneLockScreenContainer>
    </IphoneBody>
  )
}

export default IPhoneLockScreen
