import { Form } from 'components'
import { CampaignParams, FormFieldType, ModalWindow } from 'types'
import { useAppDispatch } from 'app/hooks'
import { ModalHeader } from 'components/Modal'
import { closeModal } from 'slices/modal'

const CampaignDetails = ({
  args: { name, description, is_template = false, onSubmit },
  modalWindow
}: {
  args: {
    name: string
    onSubmit: (values: CampaignParams) => void
    description: string
    is_template?: boolean
  }
  modalWindow: ModalWindow
}) => {
  const dispatch = useAppDispatch()
  const campaignNameFieldsData = {
    name,
    description,
    is_template
  }

  const submit = (values: CampaignParams) => {
    onSubmit(values)
    dispatch(closeModal())
  }
  let visibleFields = campaignNameFields
  if (typeof is_template === 'undefined')
    visibleFields = campaignNameFields.filter(
      field => field.name !== 'isTemplate'
    )

  return (
    <>
      <ModalHeader
        title={`Campaign${is_template ? ' Template' : ''} Details`}
      />
      <Form
        fields={visibleFields}
        data={campaignNameFieldsData}
        isLoading={false}
        submit={submit}
        submitText="Save"
      />
    </>
  )
}

const campaignNameFields: FormFieldType[] = [
  {
    fieldType: 'Input',
    type: 'text',
    name: 'name',
    label: 'Name'
  },
  {
    fieldType: 'Input',
    type: 'text',
    name: 'description',
    label: 'Description'
  }
]

export default CampaignDetails
