import { useAppDispatch, useAppSelector } from 'app/hooks'
import { Button, ErrMsg, TextBody } from 'components'
import { ModalFooter, ModalHeader } from 'components/Modal'
import { deleteCampaign, selectCampaign } from 'slices/campaign'
import { closeModal } from 'slices/modal'
import { ModalWindow } from 'types'
import { RequestStatus } from 'utils'

const DeleteCampaignConfirmation = ({
  args
}: {
  args: {
    campaignId: number
    isTemplate: boolean
    callback: () => void
  }
  modalWindow: ModalWindow
}) => {
  const { campaignId, isTemplate, callback } = args
  const dispatch = useAppDispatch()
  const { error, loading } = useAppSelector(selectCampaign)
  const isLoading = loading === RequestStatus.Pending

  const handleDelete = () => {
    dispatch(
      deleteCampaign({
        campaign_id: campaignId
      })
    )
      .unwrap()
      .then(() => dispatch(closeModal()))
      .finally(callback)
  }

  const handleCancel = () => {
    dispatch(closeModal())
  }

  return (
    <>
      <ModalHeader title="Confirmation" />
      <ErrMsg errMsg={error?.detail} />
      <TextBody as="p" size="large" style={{ marginTop: '1rem' }}>
        {`Are you sure you want to delete this campaign${isTemplate ? ' template' : ''}?`}
      </TextBody>
      <ModalFooter>
        <Button
          color="secondary"
          size="medium"
          onClick={handleCancel}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          size="medium"
          onClick={handleDelete}
          disabled={isLoading}
        >
          {isLoading ? 'Deleting...' : 'Delete'}
        </Button>
      </ModalFooter>
    </>
  )
}

export default DeleteCampaignConfirmation
