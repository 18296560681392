import { CheckboxInterface } from 'types'
import {
  CheckboxInput,
  CheckboxLabel,
  CheckboxText,
  CheckboxView
} from './Checkbox.styled'
import ErrMsg from 'components/ErrMsg'

const Checkbox = ({
  label,
  name,
  checked,
  onChange,
  disabled,
  className,
  error
}: CheckboxInterface) => {
  return (
    <>
      <CheckboxLabel className={className} htmlFor={name}>
        <CheckboxInput
          type="checkbox"
          id={name}
          name={name}
          onChange={onChange}
          checked={checked}
          disabled={disabled}
        />
        <CheckboxView />
        {label && <CheckboxText disabled={disabled}>{label}</CheckboxText>}
      </CheckboxLabel>
      <ErrMsg style={{ marginTop: -20 }} errMsg={error} />
    </>
  )
}

export default Checkbox
