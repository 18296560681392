import { useAppDispatch, useAppSelector } from 'app/hooks'
import { Button, ErrMsg, TextBody } from 'components'
import { ModalFooter, ModalHeader } from 'components/Modal'
import { fetchCampaign } from 'slices/campaign'
import {
  deleteCampaignContent,
  selectCampaignContent
} from 'slices/campaignContent'
import { closeModal } from 'slices/modal'
import { ModalWindow } from 'types'
import { RequestStatus } from 'utils'

const DeleteChannelContent = ({
  args
}: {
  args: {
    contentId: number
    channel: string
    campaignId: number
    onDelete?: () => void
  }
  modalWindow: ModalWindow
}) => {
  const { contentId, channel, campaignId, onDelete } = args
  const dispatch = useAppDispatch()
  const { error, loading } = useAppSelector(selectCampaignContent)
  const isLoading = loading === RequestStatus.Pending

  const handleDelete = () => {
    dispatch(
      deleteCampaignContent({
        campaign_content_id: contentId
      })
    )
      .unwrap()
      .then(() => dispatch(closeModal()))
      .finally(() =>
        dispatch(
          fetchCampaign({ campaign_id: campaignId, with_related: 'true' })
        )
      )
      .finally(() => onDelete?.())
  }

  const handleCancel = () => {
    dispatch(closeModal())
  }

  return (
    <>
      <ModalHeader title="Delete Channel Content?" />
      <ErrMsg errMsg={error?.detail} />
      <TextBody as="p" size="large" style={{ marginTop: '1rem' }}>
        {`Deleting the ${channel.toLowerCase()} channel from this campaign will
        delete all associated content for the channel. Are you sure you want to
        delete the channel?`}
      </TextBody>
      <ModalFooter>
        <Button
          color="secondary"
          size="medium"
          onClick={handleCancel}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          color="delete"
          size="medium"
          onClick={handleDelete}
          disabled={isLoading}
        >
          {isLoading ? 'Deleting...' : 'Delete'}
        </Button>
      </ModalFooter>
    </>
  )
}

export default DeleteChannelContent
