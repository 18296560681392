import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { makeQueryParams, RequestError, RequestStatus } from 'utils'
import { Campaigns } from 'types'

export interface CampaignsState {
  data: Campaigns
  templates: Campaigns
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}
export interface CampaignsAPI {
  data: Campaigns
  links: {
    next: string
  }
}

export type GetCampaignsParams = {
  with_related?: boolean | string
  search?: string
  campaign_type?: string
  campaign_status?: string
  'sort-by'?: string
  'sort-direction'?: string
  limit?: number
  cursor?: string
  is_template?: boolean
}

const initialState: CampaignsState = {
  data: [],
  templates: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export const fetchCampaigns = createAsyncThunk<
  CampaignsAPI,
  GetCampaignsParams,
  { state: RootState; rejectValue: RequestError }
>('campaigns/fetchCampaigns', async (params, { getState, rejectWithValue }) => {
  try {
    const queryParams = params
      ? makeQueryParams({
          is_template: false,
          ...params
        })
      : ''
    const endpoint = `campaigns${queryParams}`
    const api = getState().authUser.api
    return (await api?.request(endpoint)) as CampaignsAPI
  } catch (err) {
    return rejectWithValue(err as RequestError)
  }
})

export const fetchTemplates = createAsyncThunk<
  CampaignsAPI,
  GetCampaignsParams,
  { state: RootState; rejectValue: RequestError }
>('campaigns/fetchTemplates', async (params, { getState, rejectWithValue }) => {
  try {
    const queryParams = params
      ? makeQueryParams({
          is_template: true,
          is_archived: false,
          ...params
        })
      : ''
    const endpoint = `campaigns${queryParams}`
    const api = getState().authUser.api
    return (await api?.request(endpoint)) as CampaignsAPI
  } catch (err) {
    return rejectWithValue(err as RequestError)
  }
})

export const campaignsSlice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    resetCampaigns: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchCampaigns.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchCampaigns.fulfilled, (state, { payload }) => {
      state.data = payload.data
      state.links = payload.links
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchCampaigns.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
    })
    builder.addCase(fetchTemplates.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchTemplates.fulfilled, (state, { payload }) => {
      state.templates = payload.data
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchTemplates.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
    })
  }
})
export const { resetCampaigns } = campaignsSlice.actions

export const selectCampaigns = (state: RootState) => state.campaigns

export const selectCampaignsOptions = createSelector(
  (state: RootState) => {
    return state.campaigns.data
  },
  campaigns => {
    return (
      campaigns?.map(
        ({ campaign_id, name }: { campaign_id: number; name: string }) => ({
          text: name,
          value: campaign_id
        })
      ) || ([] as { text: string; value: number }[])
    )
  }
)

export default campaignsSlice.reducer
