import styled from '@emotion/styled'
import { css } from '@emotion/react'

const IphoneContainer = styled.div`
  position: relative;
  z-index: 1;
  height: 694px;
  width: 342px;
`

const Side = styled.div`
  background: #000000;
  border-radius: 54px;
  height: 694px;
  padding: 15px 22px;
  width: 342px;
  z-index: 1;
`

const ScreenView = styled.div`
  background: #ffffff;
  background-position: center center;
  background-size: cover;
  border-radius: 41px;
  position: relative;
  height: 664px;
  width: 313px;
  margin-left: -8px;
  margin-top: 0;
  z-index: 198;
  overflow: hidden;
`

const Line = styled.div``

const Header = styled.div`
  background: #000000;
  border-bottom-left-radius: 13px;
  border-bottom-right-radius: 13px;
  height: 24px;
  left: 50%;
  margin-left: -82px;
  position: absolute;
  top: 15px;
  width: 164px;
  z-index: 199;

  .sensor-2::after,
  .sensor-2::before {
    content: '';
    position: absolute;
  }
  .sensor-2::before {
    background: #000000;
    border-radius: 2.5px;
    height: 5px;
    width: 40px;
    left: 50%;
    margin-left: -20px;
    top: 7px;
  }
  .sensor-3::after,
  .sensor-3::before {
    content: '';
    position: absolute;
  }
  .sensor-3::before {
    background: #000000;
    border-radius: 50%;
    height: 11px;
    width: 11px;
    left: 50%;
    margin-left: 35px;
    top: 3px;
  }
`

export const Sensor1 = styled.div``

export const Sensor2 = styled.div``

export const Sensor3 = styled.div``

export const VolumeButton = styled.div`
  background: #000000;
  height: 26px;
  left: -2px;
  position: absolute;
  top: 92px;
  width: 3px;

  ${css`
    &::after,
    &::before {
      content: '';
      position: absolute;
      background: #000000;
      height: 50px;
      left: 0;
      width: 3px;
    }
    &::after {
      top: 48px;
    }
    &::before {
      top: 112px;
    }
  `}
`

export const PowerButton = styled.div`
  background: #000000;
  height: 80px;
  right: -2px;
  position: absolute;
  top: 160px;
  width: 3px;
`

interface IPhoneBodyProps {
  children?: React.ReactNode
}

const Iphone = ({ children }: IPhoneBodyProps) => {
  return (
    <IphoneContainer>
      <Side>
        <ScreenView>{children}</ScreenView>
      </Side>
      <Line />
      <Header>
        <Sensor1 className="sensor-1" />
        <Sensor2 className="sensor-2" />
        <Sensor3 className="sensor-3" />
      </Header>
      <VolumeButton className="volume-button" />
      <PowerButton></PowerButton>
    </IphoneContainer>
  )
}

export default Iphone
